import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { AppState } from '@ttstr/reducers';
import { receiveProducts, receiveTickets } from '@ttstr/actions';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

import Alert from 'reactstrap/lib/Alert';
import Fade from 'reactstrap/lib/Fade';

import {
  Container,
  ContentrArea,
  ProductDetailHeader,
  useProductDetails,
  LoadingSpinner,
  useIntl,
  Masonry,
  Maki2020,
  InfoSection,
} from '@ttstr/components';

interface RouteParams {
  readonly id: string;
}

const CategoryView: React.FC = () => {
  const params = useParams<RouteParams>();
  const { t } = useTranslation();
  const { language } = useIntl();

  const { categories, categoriesReceived } = useProductDetails();
  const { products, productsLoading, tickets, ticketsLoading } = useShallowEqualSelector(mapStateToProps);
  const { receiveProducts, receiveTickets } = useActions(mapDispatchToProps);

  React.useEffect(() => {
    receiveProducts({ category_ids: Number(params.id) });
    receiveTickets({ category_ids: Number(params.id) });
  }, [language, params.id, receiveProducts, receiveTickets]);

  if (!categoriesReceived) {
    return <LoadingSpinner />;
  }

  return (
    <article className="mb-5 category-detail">
      <Helmet>
        <body className="category-detail-page" />
      </Helmet>

      {categories && (
        <Fade>
          {categories[Number(params.id)]?.hero_image?.url && (
            <ProductDetailHeader
              className="category-header"
              background={categories[Number(params.id)].hero_image.url}
              image={categories[Number(params.id)].hero_image.url}
            />
          )}
          <InfoSection
            title={categories[Number(params.id)].title}
            subtitle={categories[Number(params.id)].subtitle}
            imageUrl={categories[Number(params.id)].image.url}
            description={categories[Number(params.id)].description}
          />
        </Fade>
      )}

      <Container>
        <ContentrArea id="category-before-listing" />
      </Container>

      <Container className="mt-5">
        {/* <h2 className="text-center mb-4 d-none">{t(`CATEGORY.PRODUCT_TITLE`)}</h2> */}
        {productsLoading || ticketsLoading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.PRODUCTS`)} />
        ) : products.length === 0 && tickets.length === 0 ? ( // if no products
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show products
          <Masonry
            id="product-listing"
            products={products
              .concat(tickets) // take Products and Tickets
              .filter((p) => p.category_ids.includes(categories[Number(params.id)].id)) // filter by the category we're in
              .sort((a, b) => new Intl.Collator(language).compare(a.title, b.title))} // sort alphabetically and language sensitive
            filters={['fulltext', 'brandId', 'categoryId', 'tagId']}
            showSubtitle
            showSupertitle
            showFormerPrice
          />
        )}
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { products } = state.Products;
  const productsLoading = state.Products.loading;
  const { tickets } = state.Tickets;
  const ticketsLoading = state.Tickets.loading;
  return {
    products,
    productsLoading,
    tickets,
    ticketsLoading,
  };
};

const mapDispatchToProps = {
  receiveProducts,
  receiveTickets,
};

export default CategoryView;
