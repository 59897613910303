import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

import { Product } from '@ttstr/api/products';
import {
  Container,
  NativeSlider,
  VariantChooser,
  useShopConfig,
  ContentrArea,
  useProductDetails,
  useProductDetailLink,
} from '@ttstr/components';

interface OwnProps {
  readonly product: Product;
}

type Props = Readonly<OwnProps>;

const ProductDetail: React.FC<Props> = ({ product }) => {
  const { t } = useTranslation();
  const { canonicalUrl } = useShopConfig();
  const { brands } = useProductDetails();
  const productDetailLink = useProductDetailLink();

  const images = React.useMemo(() => [product.hero_image.url, ...product.gallery_image_urls.map((i) => i.original)], [
    product,
  ]);
  const thumbs = React.useMemo(
    () => [product.hero_image.thumb.url, ...product.gallery_image_urls.map((i) => i.thumb)],
    [product]
  );

  return (
    <article className="product-detail mt-md-5" itemScope itemType="http://schema.org/Product">
      <Helmet>
        {/**
         * The title, headline or name of the object.
         * max 70 char
         */}
        <meta property="og:title" content={product.title} />
        {/**
         * 2 - 4 sentences
         * max 200 char
         * You should not re-use the title as the description or use this field to describe the general services provided by the website.
         */}
        <meta name="twitter:card" content={product.description} />
        <meta property="og:description" content={product.description} />
        <meta property="og:type" content="article" />
        {/**
         * Min 600×315 px, preferred > 1200×630 px
         * Max 1MB
         * ~1.91:1 ratio
         */}
        <meta property="og:image" content={product.image.url} />
        <meta property="og:url" content={productDetailLink(product, { canonicalUrl })} />
        <title>
          {product.supertitle && product.supertitle + ' - '}
          {product.title}
          {product.subtitle && ' - ' + product.subtitle}
        </title>
        <link rel="canonical" href={productDetailLink(product, { canonicalUrl })} />
        <body
          className={`product-detail-page inverse-navbar ${product.category_ids
            .map((id) => 'category-id-' + id)
            .toString()
            .replace(/,/g, ' ')} ${product.tag_ids
            .map((id) => 'tag-id-' + id)
            .toString()
            .replace(/,/g, ' ')}`}
        />
      </Helmet>
      <meta itemProp="sku" content={String(product.id)} />

      <section className="product-content">
        <Container className="detail-page">
          <Row>
            <Col lg="6">
              <NativeSlider
                images={images.length ? images : [product.hero_image.url]}
                thumbs={thumbs.length ? thumbs : [product.hero_image.thumb.url]}
              />
            </Col>
            <Col lg="6" className="text-center text-lg-left">
              <header>
                {product.supertitle && <span className="text-muted">{product.supertitle}</span>}
                <h1 className="product-detail-title">
                  <span itemProp="name">{product.title}</span>
                  {product.subtitle && <small>{product.subtitle}</small>}
                </h1>
              </header>
              <ContentrArea id="before-variants" />
              <VariantChooser className="mb-4" product={product} showFormerPrice />
              <ContentrArea id="after-variants" />
              {product.description && (
                <>
                  <h2 className="smaller d-none">{t(`PRODUCT.INFORMATION`)}</h2>
                  <div dangerouslySetInnerHTML={{ __html: product.description }} itemProp="description" />
                </>
              )}
              {/* {product.brand_ids.length &&
                brands &&
                product.brand_ids.map((brandId) => (
                  <Link key={brandId} to={`/brand/${brandId}`} className={`brand_link brand_link-${brandId} d-block`}>
                    {t(`PRODUCT.MORE_FROM_BRAND_LINK`, { brand: brands[brandId]?.title })}
                  </Link>
                ))} */}
              <small className="d-block text-muted mt-2">{t(`PRODUCT.EXCLVAT`)}</small>
              <ContentrArea id="general-info" />
            </Col>
          </Row>
        </Container>
      </section>
    </article>
  );
};

export default React.memo(ProductDetail);
